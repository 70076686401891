<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Policy Administration Point
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <card-component>
        <em>
          <p class="pb-2">Manage Contract Agreements and Contract Offers in the Policy Administration Point, that are used in the internal Policy Enforcement Framework.</p>
          <p class="pb-2">Contract Agreements are primarily instantiated from the Contract Negotiation sequence, this results in only very rarely having the need to insert custom Contract Agreements. Only when two parties agree on a contract outside of the negotiation sequence this option should be used (e.g. when two parties create an overarching contract agreement).</p>
          <p>Contract Offers are primarily instantiated from the metadata of resources, which makes the Contract Offers visible for consumers via DescriptionRequest or Broker queries. Only when Data Apps are used that don't provide Contract Offers, inserting a manual Contract Offer should be used.</p>
        </em>
      </card-component>
      <card-component
        title="Contract Agreements"
        class="has-table has-mobile-sort-spaced"
        icon="file-check-outline"
        headerColor="info"
      >
        <b-table
          :loading="isLoading"
          :paginated="paginated"
          :per-page="15"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :data="agreementsData"
        >
          <b-table-column label="Agreement ID" field="agreementId" sortable v-slot="props">
            {{ props.row.agreementId }}
          </b-table-column>
          <b-table-column label="Provider ID" field="providerId" sortable v-slot="props">
            {{ props.row.providerId }}
          </b-table-column>
          <b-table-column label="Consumer ID" field="consumerId" sortable v-slot="props">
            {{ props.row.consumerId }}
          </b-table-column>
          <b-table-column label="Date" field="contractStart" sortable v-slot="props">
            {{ props.row.contractStart }}
            <template v-if="props.row.contractEnd"> - {{ props.row.contractEnd }}</template>
          </b-table-column>
          <b-table-column label="Rules" field="rules" sortable v-slot="props">
            {{ (props.row.permission) ? props.row.permission.length : 0  }} Permissions,
            {{ (props.row.prohibition) ? props.row.prohibition.length : 0  }} Prohibitions,
            {{ (props.row.obligation) ? props.row.obligation.length : 0  }} Obligations
          </b-table-column>
          <b-table-column label="Actions" v-slot="props">
            <b-icon
              icon="delete"
              type="is-danger"
              class="is-clickable"
              @click.native="deleteAgreement(props.row.agreementId)"
            >
            </b-icon>
            <b-icon
              icon="file-search"
              type="is-info"
              class="is-clickable"
              @click.native="contractModal = {isActive: true, contract: props.row.contract}"
            >
            </b-icon>
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No contract agreements present...</p>
            </div>
          </section>
        </b-table>
      </card-component>
    </section>
    <section class="section is-main-section"><card-component
        title="Contract Offers"
        class="has-table has-mobile-sort-spaced"
        icon="file-lock-outline"
        headerColor="info"
      >
        <b-table
          :loading="isLoading"
          :paginated="paginated"
          :per-page="15"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :data="offersData"
        >
          <b-table-column label="Agreement ID" field="offerId" sortable v-slot="props">
            {{ props.row.offerId }}
          </b-table-column>
          <b-table-column label="Consumer ID" field="consumerId" sortable v-slot="props">
            <template v-if="props.row.providerId">
            {{ props.row.providerId }}
            </template>
            <template v-else>*</template>
          </b-table-column>
          <b-table-column label="Rules" field="rules" sortable v-slot="props">
            {{ (props.row.permission) ? props.row.permission.length : 0  }} Permissions,
            {{ (props.row.prohibition) ? props.row.prohibition.length : 0  }} Prohibitions,
            {{ (props.row.obligation) ? props.row.obligation.length : 0  }} Obligations
          </b-table-column>
          <b-table-column label="Actions" v-slot="props">
            <b-icon
              icon="delete"
              type="is-danger"
              class="is-clickable"
              @click.native="deleteOffer(props.row.offerId)"
            >
            </b-icon>
            <b-icon
              icon="file-search"
              type="is-info"
              class="is-clickable"
              @click.native="contractModal = {isActive: true, contract: props.row.contract}"
            >
            </b-icon>
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No contract offers present...</p>
            </div>
          </section>
        </b-table>
      </card-component>
      <tiles>
        <card-component title="New contract offer" icon="file-document-edit" headerColor="success" class="tile is-child">
          <form @submit.prevent="insertOffer">
            <b-field label="Contract Offer" horizontal>
              <b-input
                v-model="contract.offer"
                type="textarea"
                :placeholder="offerPlaceholder"
                rows="10"
                required
              />
            </b-field>
            <hr />
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button native-type="submit" type="is-primary"
                    >Insert contract offer</b-button
                  >
                </div>
                <div class="control">
                  <b-button type="is-primary is-outlined" @click="()=>{contract.offer = ''}"
                    >Reset</b-button
                  >
                </div>
              </b-field>
            </b-field>
          </form>
        </card-component>
        <card-component title="New contract agreement" icon="file-document-edit" headerColor="success" class="tile is-child">
          <form @submit.prevent="insertAgreement">
            <b-field label="Contract Agreement" horizontal>
              <b-input
                v-model="contract.agreement"
                type="textarea"
                :placeholder="agreementPlaceholder"
                rows="10"
                required
              />
            </b-field>
            <hr />
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button native-type="submit" type="is-primary"
                    >Insert contract agreement</b-button
                  >
                </div>
                <div class="control">
                  <b-button type="is-primary is-outlined" @click="()=>{contract.agreement = ''}"
                    >Reset</b-button
                  >
                </div>
              </b-field>
            </b-field>
          </form>
        </card-component>
      </tiles>
      <contract-request-card @requestedContract="getAgreements" />
      <b-modal :active.sync="contractModal.isActive" has-modal-card>
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Contract</p>
                </header>
                <section class="modal-card-body">
                  <vue-json-editor v-model="contractModal.contract" :modes="['view', 'text']" mode="view" :show-btns="true" :expandedOnStart="true"></vue-json-editor>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="contractModal.isActive = false">Close</button>
                </footer>
            </div>
        </b-modal>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import ContractRequestCard from '@/components/ContractRequestCard'
import vueJsonEditor from 'vue-json-editor'

export default {
  name: 'PolicyAdministrationPoint',
  components: {
    HeroBar,
    CardComponent,
    TitleBar,
    Tiles,
    ContractRequestCard,
    vueJsonEditor
  },
  data () {
    return {
      isLoading: false,
      paginated: true,
      agreements: [],
      offers: [],
      contract: {
        offer: `{
  "@context": {
    "ids": "https://w3id.org/idsa/core/",
    "idsc": "https://w3id.org/idsa/code/"
  },
  "@type": "ids:ContractOffer",
  "@id": "https://w3id.org/idsa/autogen/contractOffer/00000000-0000-0000-0000-000000000000",
  "ids:permission": [
    {
      "@type": "ids:Permission",
      "@id": "https://w3id.org/idsa/autogen/permission/00000000-0000-0000-0000-000000000000",
      "ids:action": [
        {
          "@id": "https://w3id.org/idsa/code/READ"
        }
      ],
      "ids:target": {
        "@id": "http://plugfest2021.08.localhost.demo/resources/00000000-0000-0000-0000-000000000000"
      }
    }
  ]
}`,
        agreement: `{
    "@context": {
      "ids": "https://w3id.org/idsa/core/",
      "idsc": "https://w3id.org/idsa/code/"
    },
    "@type": "ids:ContractAgreement",
    "@id": "https://w3id.org/idsa/autogen/contractAgreement/00000000-0000-0000-0000-000000000000",
    "ids:consumer": {
      "@id": "http://plugfest2021.08.localhost.demo"
    },
    "ids:contractStart": {
      "@value": "2021-10-10T19:20:01.285Z",
      "@type": "http://www.w3.org/2001/XMLSchema#dateTimeStamp"
    },
    "ids:contractEnd": {
      "@value": "2022-01-10T20:20:01.285Z",
      "@type": "http://www.w3.org/2001/XMLSchema#dateTimeStamp"
    },
    "ids:contractDate": {
      "@value": "2021-10-10T19:20:01.285Z",
      "@type": "http://www.w3.org/2001/XMLSchema#dateTimeStamp"
    },
    "ids:prohibition": [],
    "ids:obligation": [],
    "ids:provider": {
      "@id": "http://plugfest2021.08.localhost.demo"
    },
    "ids:permission": [
      {
        "@type": "ids:Permission",
        "@id": "https://w3id.org/idsa/autogen/permission/00000000-0000-0000-0000-000000000000",
        "ids:description": [],
        "ids:title": [],
        "ids:assignee": [
          {
            "@id": "http://plugfest2021.08.localhost.demo"
          }
        ],
        "ids:postDuty": [],
        "ids:preDuty": [],
        "ids:constraint": [],
        "ids:action": [
          {
            "@id": "https://w3id.org/idsa/code/READ"
          }
        ],
        "ids:assigner": [
          {
            "@id": "http://plugfest2021.08.localhost.demo"
          }
        ],
        "ids:target": {
          "@id": "http://plugfest2021.08.localhost.demo/resources/00000000-0000-0000-0000-000000000000"
        }
      }
    ]
  }`
      },
      contractModal: {
        isActive: false,
        contract: null
      },
      offerPlaceholder: `{
  "@type": "ids:ContractOffer",
  "@id": "https://w3id.org/idsa/autogen/contractOffer/00000000-0000-0000-0000-000000000000",
  ...
}`,
      agreementPlaceholder: `{
  "@type": "ids:ContractAgreement",
  "@id": "https://w3id.org/idsa/autogen/contractAgreement/00000000-0000-0000-0000-000000000000",
  ...
}`
    }
  },
  computed: {
    titleStack () {
      return ['Policy Enforcement Framework', 'Policy Administration Point']
    },
    agreementsData () {
      return this.agreements.map(agreement => {
        return {
          agreementId: agreement['@id'],
          providerId: agreement['ids:provider']?.['@id'],
          consumerId: agreement['ids:consumer']?.['@id'],
          contractStart: this.parseDate(agreement['ids:contractStart'], 'DD-MM-YYYY'),
          contractEnd: this.parseDate(agreement['ids:contractEnd'], 'DD-MM-YYYY'),
          permission: agreement['ids:permission'],
          prohibition: agreement['ids:prohibition'],
          obligation: agreement['ids:obligation'],
          contract: agreement
        }
      })
    },
    offersData () {
      return this.offers.map(offer => {
        return {
          offerId: offer['@id'],
          consumerId: offer['ids:consumer'],
          permission: offer['ids:permission'],
          prohibition: offer['ids:prohibition'],
          obligation: offer['ids:obligation'],
          contract: offer
        }
      })
    },
    ...mapState(['api'])
  },
  async created () {
    this.agreementInterval = setInterval(this.getAgreements, 30000)
    this.offerInterval = setInterval(this.getOffers, 30000)
    await Promise.all([
      this.getAgreements(),
      this.getOffers()
    ])
  },
  beforeDestroy () {
    clearInterval(this.agreementInterval)
    clearInterval(this.offerInterval)
  },
  methods: {
    async getAgreements () {
      try {
        const agreements = await this.api.get('/pap/contracts')
        this.agreements = agreements.data
      } catch (e) {
        clearInterval(this.interval)
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 10000,
          queue: false,
          cancelText: 'OK',
          actionText: 'Retry',
          onAction: () => {
            clearInterval(this.agreementInterval)
            this.getAgreements()
            this.agreementInterval = setInterval(this.getAgreements, 30000)
          }
        })
        console.log(e)
      }
    },
    async getOffers () {
      try {
        const offers = await this.api.get('/pap/offers')
        this.offers = offers.data
      } catch (e) {
        clearInterval(this.interval)
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 10000,
          queue: false,
          cancelText: 'OK',
          actionText: 'Retry',
          onAction: () => {
            clearInterval(this.offerInterval)
            this.getOffers()
            this.offerInterval = setInterval(this.getOffers, 30000)
          }
        })
        console.log(e)
      }
    },
    async insertOffer () {
      try {
        await this.api.post('/pap/offers', this.contract.offer, {
          headers: {
            'Content-Type': 'application/ld+json'
          }
        })
        this.$buefy.toast.open({
          message: 'Successfully inserted the contract offer',
          type: 'is-success',
          position: 'is-bottom'
        })
        this.contract.offer = null
        this.getOffers()
      } catch (e) {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom'
        })
        console.log(e)
      }
    },
    async insertAgreement () {
      try {
        await this.api.post('/pap/contracts', this.contract.agreement, {
          headers: {
            'Content-Type': 'application/ld+json'
          }
        })
        this.$buefy.toast.open({
          message: 'Successfully inserted the contract agreement',
          type: 'is-success',
          position: 'is-bottom'
        })
        this.contract.agreement = null
        this.getAgreements()
      } catch (e) {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom'
        })
        console.log(e)
      }
    },
    async deleteAgreement (agreementId) {
      this.$buefy.dialog.confirm({
        title: 'Delete contract agreement',
        message: `Are you sure you want to permanently delete the contract agreement ${agreementId}?`,
        type: 'is-danger',
        onConfirm: async () => {
          try {
            await this.api.delete(
              `/pap/contracts/${encodeURIComponent(agreementId)}`
            )
            await this.getAgreements()
          } catch (e) {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              position: 'is-bottom'
            })
            console.log(e)
          }
        }
      })
    },
    async deleteOffer (offerId) {
      this.$buefy.dialog.confirm({
        title: 'Delete contract offer',
        message: `Are you sure you want to permanently delete the contract offer ${offerId}?`,
        type: 'is-danger',
        onConfirm: async () => {
          try {
            await this.api.delete(
              `/pap/offers/${encodeURIComponent(offerId)}`
            )
            await this.getOffers()
          } catch (e) {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              position: 'is-bottom'
            })
            console.log(e)
          }
        }
      })
    },

    parseDate (date, format) {
      if (date?.['@value']) {
        return moment(date?.['@value']).tz(moment.tz.guess()).format(format || 'DD-MM-YYYY HH:mm:ss z')
      } else {
        return null
      }
    }
  }
}
</script>
